import React from 'react'
import Contact from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ContactOne(){
return(
<Contact backgroundColor="#f3f4f6">
  <Container>
    <Row>
      <Col className="col-xl-7 col-lg-7">
        <Contact.Box >
            <SectionTitle
            subTitle="CONTACTO" 
            title="Déjanos tu mensaje"
            text="Para estudiar tu caso y poder hacerte una propuesta a tu medida, escríbenos directamente llenando nuestro formulario. Te responderemos en un plazo de 12 a 24 hrs."
            subTitleProps={{mb:"10px"}}
            titleProps={{mb:"10px",as:"h2"}}
            mb="50px" />
        </Contact.Box>
        <Contact.From>
            <form action="./">
                    <Row>
                      <Col xs="12" className="col-lg-6 mb-4">
                        <div className="form-floating">
                          <input className="form-control" placeholder="Leave a comment here" id="floatinginput" />
                          <label htmlFor="floatinginput">Your Email</label>
                        </div>
                      </Col>
                      <Col xs="12" className="col-lg-6 mb-4">
                        <div className="form-floating">
                          <input className="form-control" placeholder="Leave a comment here" id="floatinginput2" />
                          <label htmlFor="floatinginput2">Phone number</label>
                        </div>
                      </Col>
                      <Col xs="12" className="col-lg-12">
                        <div className="form-floating">
                          <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea3"/>
                          <label htmlFor="floatingTextarea3">Your Message Here </label>
                        </div>
                      </Col>
                      <Col xs="12" className="col-lg-12">
                        <Row className="align-items-center mt-3">
                          <div className="col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3">
                            <div className="form-check d-flex align-items-center">
                              <input className="form-check-input bg-white float-none mt-0" type="checkbox" defaultValue id="flexCheckDefault" />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                              He leído y estoy de acuerdo con el Aviso de privacidad.
                              </label>
                            </div>
                          </div>
                          <Col xs="12" className="col-md-4 col-lg-5 col-xl-4 text-md-end pt-3">
                            <Contact.Button>Enviar</Contact.Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
        </Contact.From>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-5">
        <Contact.WidgetsBox className="contact-widget-box">
            <Contact.WidgetsBoxTitle as="h2">Información</Contact.WidgetsBoxTitle>
            <Contact.WidgetsBoxText as="p">Si prefieres contactarnos directamente esta<br className="d-none d-xl-block" />  es nuestra información de contacto.</Contact.WidgetsBoxText>
          <Row>
          <Col xs="12" className="col-lg-12 col-sm-6">
            <Contact.Widgets>
              <Contact.WidgetsIcon>
                <i className="fas fa-map-marker-alt" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsTitle as="h3">Dirección:</Contact.WidgetsTitle>
                <Contact.WidgetsText as="p">Montes Urales 424, Lomas de Chapultepec III Secc, CDMX</Contact.WidgetsText>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
            <Col xs="12" className="col-lg-12 col-sm-6">
              <Contact.Widgets>
                <Contact.WidgetsIcon className="active">
                  <i className="fas fa-envelope" />
                </Contact.WidgetsIcon>
                <Contact.WidgetsBoxBody>
                  <Contact.WidgetsTitle as="h3">Correo electrónico:</Contact.WidgetsTitle>
                  <Contact.WidgetsText as="p">contacto@helios.com</Contact.WidgetsText>
                </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
            <Col xs="12" className="col-lg-12 col-sm-6 active">
            <Contact.Widgets>
              <Contact.WidgetsIcon>
                <i className="fas fa-phone-alt" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsTitle as="h3">Teléfonos:</Contact.WidgetsTitle>
                <Contact.WidgetsText as="p">0000000000 <br className="d-block" /> 1234567891</Contact.WidgetsText>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
          </Row>
        </Contact.WidgetsBox>
      </Col>
    </Row>
  </Container>
</Contact>

)
}